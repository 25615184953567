/** @format **/

import { global, staticSchema } from '../../constants';
import { RaygunConfiguration, StaticData, PageContext } from '../../types';

import { SummaryFavoriteDatum } from './types';
import { getIntegrationIdByName, getSummaryFavoriteIdByName } from './utils';

export function getEnabledIntegrations(config: RaygunConfiguration): number[] {
  const integrationsCollection = !!config && config.ErrorDashboardPlugins;
  const enabledIntegrations = [];

  if (integrationsCollection != null && integrationsCollection.length > 0) {
    for (let i = 0; i < integrationsCollection.length; i++) {
      const integration = integrationsCollection[i];

      if (integration != null) {
        enabledIntegrations.push(getIntegrationIdByName(integration.Name));
      }
    }
  }

  return enabledIntegrations;
}

export function getSummaryFavorites(config: RaygunConfiguration): number[] {
  const summaryFavoritesJson = !!config && config.SummaryFavourites;

  if (summaryFavoritesJson == null || summaryFavoritesJson.length === 0) {
    return [];
  }

  const summaryFavorites = [];

  const summaryFavoritesCollection: SummaryFavoriteDatum[] = JSON.parse(summaryFavoritesJson);

  // These items are either permanently favorited, duplicated, or are headings for sections
  const excludedItems = [
    'Request',
    'Error',
    'User',
    'Message',
    'ClassName',
    'User-Identifier',
    'User-Email',
    'User-FullName',
    'User-FirstName',
    'WrapRawData',
  ];

  for (let j = 0; j < summaryFavoritesCollection.length; j++) {
    const summaryFavorite = summaryFavoritesCollection[j];

    if (
      summaryFavorite != null &&
      summaryFavorite.Value &&
      excludedItems.indexOf(summaryFavorite.Key) === -1
    ) {
      summaryFavorites.push(getSummaryFavoriteIdByName(summaryFavorite.Key));
    }
  }

  return summaryFavorites;
}

export function errorInstanceViewData(): StaticData {
  const config = global.RaygunConfiguration;

  return {
    enabledIntegrations: getEnabledIntegrations(config),
    summaryFavorites: getSummaryFavorites(config),
  };
}

export function errorInstanceViewContext(): PageContext[] {
  const staticPageData: PageContext[] = [];

  staticPageData.push({
    schema: staticSchema,
    data: errorInstanceViewData(),
  });

  return staticPageData;
}
