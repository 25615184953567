/** @format **/

import { errorInstanceViewData } from './crashReporting/errorInstance';

import { global } from './constants';
import * as Utils from './utils';

export function sendPageDataToSnowplow() {
  const queryStringData: string[] = [];

  // Performance concern: only add view-specific data if that view is being rendered
  if (Utils.isCrashReportingInstanceView()) {
    queryStringData.push(Utils.dataToQueryString(errorInstanceViewData()));
  }

  const originalUrl = global.location.toString();
  const queryStringDataString = queryStringData.join('&');

  // NOTE: Send the data as part of the URL query string - the context is not currently being ingested in the snowplow ingestion pipeline

  // Set the URL to send to snowplow, then switch it back to the original URL, and only if there is data to be sent
  if (queryStringDataString.length > 0) {
    global.raygunSnowplow('setCustomUrl', Utils.addQueryStringToUrl(queryStringDataString));
  }

  // Always send the trackPageView data
  global.raygunSnowplow('trackPageView', null);

  if (queryStringDataString.length > 0) {
    global.raygunSnowplow('setCustomUrl', originalUrl);
  }
}

global.addEventListener('load', sendPageDataToSnowplow);
