/** @format **/

/**
 * Encode the integration and summary favorite options to decrease the length of the URL
 * The dictionary lookup functions will throw if the input is an unknown value
 * NOTE: This will need to be decoded in the snowplow ingestion pipeline
 */

export const integrationDictionary: { [integrationName: string]: number } = {
  YouTrack: 0,
  Github: 1,
  FogBugz: 2,
  Trello: 3,
  Sprintly: 4,
  JIRA: 5,
  Bitbucket: 6,
  PivotalTracker: 7,
  BugHerd: 8,
  VisualStudioOnline: 9,
  Lighthouse: 10,
  Asana: 11,
  Intercom: 12,
  Zendesk: 13,
  CAAgileCentral: 14,
  Assembla: 15,
  GitLab: 16,
  Targetprocess: 17,
  AmazonSQS: 18,
  AzureServiceBus: 19,
};

export const summaryFavoritesDictionary: { [summaryFavoriteName: string]: number } = {
  OccurredOn: 0,
  MachineName: 1,
  Version: 2,
  'Error-Message': 3,
  'Error-ClassName': 4,
  'Environment-LocalTime': 5,
  'Error-ErrorId': 6,
  StackTrace: 7,
  'Request-HttpMethod': 8,
  'Request-HostName': 9,
  'Request-IPAddress': 10,
  'Request-Url': 11,
  'Request-UserAgent': 12,
  'Request-QueryString': 13,
  'Request-ParamValues': 14,
  'Request-CookieValues': 15,
  'Request-HeaderValues': 16,
  'Request-FormValues': 17,
  'Request-ServerVariables': 18,
  'Environment-OSVersion': 19,
  'Environment-ProcessorCount': 20,
  'Environment-OS': 21,
  'Environment-Browser': 22,
  'Environment-Locale': 23,
  Tags: 24,
  Breadcrumb: 25,
  'Response-StatusCode': 26,
  'Error-Data': 27,
  'Environment-Model': 28,
  'Environment-CPU': 29,
  'Request-RawRequestData': 30,
  'Request-Referer': 31,
  'Request-NetworkConnectivityState': 32,
  'Custom-Data': 33,
  'Environment-Architecture': 34,
  'Environment-AvailablePhysicalMemory': 35,
  'Environment-BrowserHeight': 36,
  'Environment-DeviceManufacturer': 37,
  'Environment-DeviceName': 38,
  'Response-StatusDescription': 39,
  'Request-SessionValues': 40,
  'Environment-TotalPhysicalMemory': 41,
  'Environment-AvailableVirtualMemory': 42,
  'Environment-CurrentOrientation': 43,
  'Environment-BrowserWidth': 44,
  'Environment-TotalVirtualMemory': 45,
  'Environment-ScreenWidth': 46,
  'Environment-ScreenHeight': 47,
};

export function getIntegrationIdByName(name: string): number {
  if (!integrationDictionary.hasOwnProperty(name)) {
    throw new TypeError(`Invalid integration name: ${name}`);
  }

  return integrationDictionary[name];
}

export function getSummaryFavoriteIdByName(name: string): number {
  if (!summaryFavoritesDictionary.hasOwnProperty(name)) {
    throw new TypeError(`Invalid summary favorite name: ${name}`);
  }

  return summaryFavoritesDictionary[name];
}
