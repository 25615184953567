/** @format **/

import { StaticData } from '../types';
import { global } from '../constants';

export function dataToQueryString(data: StaticData) {
  const queryStringArray = [];

  for (const key in data) {
    if (data.hasOwnProperty(key) && data[key as keyof StaticData].length > 0) {
      const dataString = JSON.stringify(data[key as keyof StaticData]);
      queryStringArray.push(`${key}=${dataString}`);
    }
  }

  return queryStringArray.join('&');
}

export function addQueryStringToUrl(
  queryString: string,
  origin: string = global.location.origin,
  pathName: string = global.location.pathname,
) {
  const rootUrl = origin + pathName;

  if (queryString == null || queryString.length === 0) {
    return rootUrl;
  }

  return `${rootUrl}?${queryString}`;
}
